import { Box, Paper } from "@mui/material";
import { ReactNode } from "react";

interface PageProps {
  children: ReactNode;
};

export function BasePage(props: PageProps) {
  return (
    <Box m={8}>
      {props.children}
    </Box>
  );
};

export function PaperPage(props: PageProps) {
  return (
    <Box m={8}>
      <Paper sx={{ padding: '16px' }}>
        {props.children}
      </Paper>
    </Box>
  );
};

