import Grid from '@mui/material/Unstable_Grid2'
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { GridContainer } from '../components/Containers';
import { PaperPage } from '../components/Pages';

import athenaPasadena from '../images/athena_pasadena.webp';

// TODO: Work on the heart (currently 'favorite' icon - not aligning well. Might just want to use FA
// NOTE: If the heart isn't aligning well - replace with period
export function About() {
  useEffect(() => {
    document.title = 'About Me!';
  }, []);

  return (
    <PaperPage>
      <GridContainer>
        <Grid xl={4} lg={4} md={4} sm={12} xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src={athenaPasadena}
              alt='Athena Kavounas (SLP) Headshot'
              style={{ width: '100%' }}
            />
          </Box>
        </Grid>
        <Grid xl={8} lg={8} md={8} sm={12} xs={12}>
          <Box>
            <ul>
              <li>I graduated from UC Davis (Go Ags!) in 2017, where I met my now husband <FontAwesomeIcon icon={faHeart} style={{ color: '#ff8080', }} /> We live in Old Town Pasadena and we're in love with our city.</li>
              <li>When I'm not working, you can find me shopping at Vroman's, taking a barre class, or enjoying a matcha latte at Coffee and Plants.</li>
              <li>At Davis, I earned my Bachelor of Arts with a double major in Linguistics and Communication.</li>
              <li>I completed my post-baccalaureate and Masters of Science degrees in Communication Sciences and Disorders at Chapman University.</li>
              <li>I have experience in the clinic and school settings, as well as experience supervising other therapists and coordinating public relations for a clinic.</li>
            </ul>
          </Box>
        </Grid>
      </GridContainer>
    </PaperPage>
  );
};

