import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'
import { CardContainer, GridContainer } from '../components/Containers';
import { PaperPage } from '../components/Pages';

import chapmanHeadshot from '../images/chapman_headshot.webp';
import ashaBadge from '../images/asha_ccc_slp_blk.webp';
import { useEffect } from 'react';

export function Home() {
  useEffect(() => {
    document.title = 'Berra Speech Therapy';
  }, []);

  return (
    <Box>
      <PaperPage>
        <GridContainer>
          <Grid xl={4} lg={4} md={4} sm={12} xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={chapmanHeadshot}
                alt='Athena Kavounas (SLP) Headshot'
                style={{ width: '100%' }}
              />
            </Box>
          </Grid>
          <Grid xl={8} lg={8} md={8} sm={12} xs={12}>
            <Box>
              Hi there! My name is Athena. I'm a licensed Speech-Language Pathologist based in Pasadena, CA. I'm passionate about helping kids with a variety of speech and language challenges. Little ones (ages 1-5) are my specialty, but I work with kids of all ages, up to teenagers. I provide evaluations and treatment and I come to you for services. I believe that therapy is the most functional and effective in the home environment, and I find that progress is faster when kids are comfortable!
            </Box>
          </Grid>
        </GridContainer>
      </PaperPage>
      <Box mb={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CardContainer>
          <img
            src={ashaBadge}
            alt='ASHA CCC SLP Block Badge'
            style={{
              maxWidth: '200px'
            }}
          />
        </CardContainer>
      </Box>
    </Box>
  );
};

